<template>
  <div>
    <v-list dense>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          Code:
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{ promotionInfo.code }} ({{ status }})
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          ประเภทส่วนลด:
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{ promotionType }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          ส่วนลด:
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{ $options.filters.showNumberFormat(promotionInfo.discountNumber) }} {{ discountUnit }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          ยอดขั้นต่ำ:
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{ isLessThanEqual(promotionInfo.minPrice, 0) ? 'ไม่มีขั้นต่ำ' : `${promotionInfo.minPrice} บาท` }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          ลดสูงสุด:
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{ isLessThanEqual(promotionInfo.maxPrice, 0) ? 'ไม่กำหนด' : `${promotionInfo.maxPrice} บาท` }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          จำนวนการใช้ต่อคน:
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{ isLessThanEqual(promotionInfo.perUser, 0) ? 'ไม่กำหนด' : `${promotionInfo.perUser} ครั้ง` }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          ใช้แล้ว:
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{ totalUsed }} สิทธิ์
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          สิทธิ์ที่ใช้ได้ทั้งหมด:
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{ isLessThanEqual(promotionInfo.amount, 0) ? 'ไม่กำหนด' : `${promotionInfo.amount} สิทธิ์` }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          เริ่ม
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{
            promotionInfo.dateRange.startDate
              ? ($options.filters.dateUserFormat(promotionInfo.dateRange.startDate))
              : 'ไม่กำหนด'
          }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          สิ้นสุด
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{ promotionInfo.dateRange.endDate
            ? ($options.filters.dateUserFormat(promotionInfo.dateRange.endDate)) : 'ไม่กำหนด' }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          หมวดหมู่สินค้า:
        </v-list-item-content>
        <v-list-item-content
          v-if="productCategories.length"
          class="pa-2 wrap-text align-start">
          <div>
            <v-chip
              v-for="(category, index) in productCategories"
              :key="`category-${index}`"
              label
              small
              class="ma-1 text-bold"
              color="black"
              text-color="white">
              {{ category }}
            </v-chip>
          </div>
        </v-list-item-content>
        <v-list-item-content
          v-else
          class="align-end pa-2">
          ไม่กำหนด
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          <u>{{ tagInclude ? 'ใช้ได้กับ tag ดังนี้' : 'ใช้ไม่ได้กับ tag ดังนี้' }}:</u>
        </v-list-item-content>
        <v-list-item-content
          v-if="productTags.length"
          class="pa-2 wrap-text align-start">
          <div>
            <v-chip
              v-for="(tag, index) in productTags"
              :key="`tag-${index}`"
              small
              label
              class="ma-1 text-bold"
              :color="tagInclude ? 'success' : 'red'"
              text-color="white">
              {{ tag }}
            </v-chip>
          </div>
        </v-list-item-content>
        <v-list-item-content
          v-else
          class="pa-2 wrap-text align-end">
          ไม่กำหนด
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          จำนวนสินค้าขั้นต่ำ:
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{ isLessThanEqual(promotionInfo.minAmount, 0) ? 'ไม่กำหนด' : `${promotionInfo.minAmount} ชิ้น` }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          ประเภทของ Promotion Code:
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{ campaignType }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          ช่องทางที่ใช้ได้:
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{ selectedChannel.join(',  ') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          โน๊ต:
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{ promotionInfo.note || 'ไม่ระบุ' }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          วันที่สร้าง:
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{ $options.filters.dateTimeFormat(promotionInfo.createdAt) }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          สร้างโดย:
        </v-list-item-content>
        <v-list-item-content class="pa-2 wrap-text align-end">
          {{ promotionInfo.createdBy.email }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="pa-2 wrap-text text-bold">
          เบอร์ลูกค้าที่ใช้ได้:
        </v-list-item-content>
        <v-list-item-content
          v-if="!tels.length"
          class="pa-2 wrap-text align-end">
          ไม่กำหนด
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="tels.length">
        <v-list-item-content
          class="pa-2 wrap-text align-start">
          <div>
            <v-chip
              v-for="(tel, index) in tels"
              :key="`tel-${index}`"
              label
              small
              class="ma-1"
              color="gray"
              text-color="black">
              {{ tel }}
            </v-chip>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
    props: {
      promotionInfo: {
        type: Object,
        default: () => ({})
      },
      totalUsed: {
        type: Number,
        default: 0
      }
    },
    data () {
        return {
          statuses: [
            { text: 'ใช้งานแล้ว', value: 'used' },
            { text: 'ยังไม่ใช้งาน', value: 'unuse' },
            { text: 'ปิดการใช้งาน', value: 'inactive' }
          ],
          channels: {
            web: 'ออนไลน์',
            pos: 'หน้าร้าน'
          },
          promotionTypes: [
            { text: 'Member (VIP / VVIP)', value: 'MEMBER' },
            { text: 'Store Credit', value: 'STORE_CREDIT' },
            { text: 'Gift voucher', value: 'GIFT_VOUCHER' },
            { text: 'Giveaway (ของแถม)', value: 'GIVEAWAY' },
            { text: 'Celeb (ส่วนลดของ Celeb)', value: 'CELEB' },
            { text: 'Benefits (ส่วนลดของพนักงาน)', value: 'EMPLOYEE_BENEFITS' }
          ],
          campaignTypes: [
            { text: 'Brand', value: 'brand_campaign' },
            { text: 'Member', value: 'member_campaign' },
            { text: 'First Online Purchase', value: 'first_online_purchase' }
          ]
        }
    },
    computed: {
      promotionInfoIsHas () { return (Object.keys(this.promotionInfo).length > 0) },
      discountUnit () { return this.promotionInfo?.discountType === 'percentage' ? '%' : 'บาท' },
      productCategories () { return this.promotionInfo?.productCategories ?? [] },
      productTags () { return this.promotionInfo?.productTags ?? [] },
      tagInclude () { return this.promotionInfo?.productTagIds?.include },
      tels () { return this.promotionInfo?.members ?? [] },
      selectedChannel () {
        return this.promotionInfo?.channel === 'all' ? Object.values(this.channels) : [this.channels[this.promotionInfo?.channel]]
      },
      promotionType () {
        return this.promotionTypes.find((mt) => mt.value === this.promotionInfo.type)?.text ?? 'ไม่กำหนด'
      },
      campaignType () {
        return this.campaignTypes.find((ct) => ct.value === this.promotionInfo.campaignType)?.text ?? 'ไม่กำหนด'
      },
      status () {
        return this.statuses.find((st) => st.value === this.promotionInfo.status)?.text ?? '-'
      }
    },
    methods: {
      isLessThanEqual (value1 = 0, value2 = 0) {
        return value1 <= value2
      }
    }
}
</script>

<style scoped>
.wrap-text {
  white-space: normal;
  overflow: visible;
  word-break: break-word;
}
</style>
